import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SectionTitle from "@components/section-title";
import BlogCard from "@components/blog-card/layout-01";
import { SectionTitleType, BlogType } from "@utils/types";

const BlogArea = ({ data, id }) => {
    const [blogData, setBlogData] = useState([]);
    const BLOG_ITEMS_API =
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@dixit.solanki";

    async function getServerSideProps() {
        const res = await fetch(`${BLOG_ITEMS_API}`);
        const data2 = await res.json();

        setBlogData(data2.items);
    }
    useEffect(() => {
        getServerSideProps();
    }, []);

    return (
        <div className="rn-blog-area rn-section-gap section-separator" id={id}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                {...data.section_title}
                                align="center"
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="500"
                                data-aos-once="true"
                            />
                        )}
                    </div>
                </div>
                <div className="row row--25 mt--30 mt_md--10 mt_sm--10">
                    {blogData &&
                        blogData.length &&
                        blogData?.map((blog, index) => (
                            <div
                                key={blog.index}
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="400"
                                data-aos-once="true"
                                className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30"
                            >
                                <BlogCard
                                    title={blog.title}
                                    id={index}
                                    slug={""}
                                    category={"ReactJS"}
                                    image={{ src: blog.thumbnail }}
                                    date={blog.pubDate}
                                    content={blog.description}
                                    readingTime={"5 min"}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

BlogArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        blogs: PropTypes.arrayOf(PropTypes.shape(BlogType)),
    }),
};

BlogArea.defaultProps = {
    id: "blog",
};

export default BlogArea;
